import axios, { AxiosRequestConfig, AxiosResponse } from 'axios'
import { ElMessage } from 'element-plus'

/** 如果某些接口采用了formdata格式，在该接口中添加 needFormData:true 属性即可 */
declare module 'axios' {
  interface AxiosRequestConfig {
    needFormData?: boolean;
  }
  interface AxiosResponse<T = any> {
    data: T;
    code?: number|string;
    msg?: string;
   }
}
const request = axios.create({
  baseURL: import.meta.env?.ENV_BASE_API || '',
  // baseURL: '/iot-admin',
  timeout: 100000,
  withCredentials: true,
})
request.interceptors.request.use((config: AxiosRequestConfig | any) => {
  const token = localStorage.getItem('lsspeedtoken') ?? ''
  if (config?.needFormData) {
    config.headers = {
      'Content-Type': 'multipart/form-data; boundary=----WebKitFormBoundarynl6gT1BKdPWIejNq',
      token,
    }
  } else {
    config.headers = {
      'Content-Type': 'application/json;charset=utf-8',
      token,
    }
  }
  return config
}, (error:any) =>
  Promise.reject(error))
request.interceptors.response.use((response: AxiosResponse) => {
  if (response.data.code) {
    switch (response.data.code) {
    case 200:
      return response.data
    case 400:
      setTimeout(() => {
        window.location.replace('')
      }, 1500)
      break
    default:
      ElMessage.error(response.data.msg)
      break
    }
  } else return response
}, (error:any) =>
  Promise.reject(error))
export default request
