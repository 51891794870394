<script setup>
</script>
<template>
  <div class="about">
    <div class="title">关于我们</div>
    <img style="width: 72px;height: 72px;" src="@/assets/images/logo.png">
    <div class="name">雷电加速器</div>
    <div class="text">
      <p>杭州棉致科技有限公司旗下雷电加速器致力于为用户提供优质的网络加速服务。</p>
      <p>采用多条优质线路，操作简单，一键加速，满足玩游戏、追剧、听音乐等多个场景的网络加速需求，确保用户网上冲浪的良好体验。</p>
      <p>杭州棉致科技有限公司是一家专注于“物联网+连接”聚合运营的创新型软件企业。</p>
      <p>公司拥有员工50余人，目前主要聚合的服务能力包括“数字生活服务平台”“互联网产品推广”等。</p>
    </div>
    <div class="tag">
      <div class="box">
        <img class="img" src="@/assets/images/icon_operate_44.png">
        丰富的互联网运营经验
      </div>
      <div class="box">
        <img class="img" src="@/assets/images/icon_technology_44.png">
        专业研发技术团队
      </div>
      <div class="box">
        <img class="img" src="@/assets/images/icon_service_44.png">
        完善的售后服务
      </div>
      <div class="box">
        <img class="img" src="@/assets/images/icon_operator_44.png">
        运营商直连资源
      </div>
    </div>
  </div>
</template>
<style lang="less" scoped>
.about {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #FFF;
  background-color: #1F2322;

  .title {
    margin: 48px 0 56px 0;
    font-size: 40px;
    line-height: 56px;
  }

  .name {
    margin: 20px 0 60px 0;
    font-size: 24px;
    line-height: 33px;
  }

  .text>p {
    font-size: 18px;
    text-align: center;
    line-height: 32px;
  }

  .tag {
    width: 100%;
    margin: 72px 200px 202px 200px;
    display: flex;
    justify-content: space-evenly;

    .img {
      margin-right: 24px;
      width: 44px;
      height: 44px;
    }

    .box {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 18px 32px;
      font-size: 18px;
      color: #FFF;
      line-height: 25px;
      border-radius: 8px;
      border: 1px solid #979797;
    }
  }
}
</style>
