<script setup>
import { ref } from 'vue'
import { ElMessage } from 'element-plus'
import { exchangeCdk } from '@/api'

const cdk = ref('')
const exchange = () => {
  if (!cdk.value) {
    ElMessage.error('请输入CDKEY编码！')
    return
  }
  exchangeCdk({ cdkCode: cdk.value, source: 10 }).then((res) => {
    if (res.code === 200) {
      ElMessage.success('兑换成功！')
    }
  })
}
</script>
<template>
  <div class="background">
    <div class="box">
      <div class="text1">CDKEY兑换</div>
      <div class="text2">CDKEY兑换免费时长</div>
      <div class="text3">
        CDKEY是由18位字符组成的序列码，输入正确可点击【兑换】获得免费时长，每个CDK限领一次
      </div>
      <span>
        <el-input v-model="cdk" class="cdkinput" size="large" placeholder="请输入CDKEY编码" />
        <el-button size="large" class="btn" type="success" @click="exchange">兑 换</el-button>
      </span>
    </div>
  </div>
</template>
<style lang="less" scoped>
.background {
  width: 100%;
  background-color: #1f2322;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 88px 0 228px 0;

  .box {
    background-color: #fff;
    color: #333333;
    padding: 32px 40px;

    .text1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 33px;
    }

    .text2 {
      margin: 24px 0 10px 0;
      font-weight: bold;
      font-size: 16px;
      line-height: 22px;
    }

    .text3 {
      margin-bottom: 20px;
      font-size: 12px;
      color: #666666;
      line-height: 17px;
    }

    .btn {
      width: 132px;
      background: linear-gradient(90deg, #00ddd5 0%, #00bdcb 100%);
      border: none;
      border-radius: 4px;
      color: #fff;

      &:hover {
        filter: brightness(0.9);
      }

      &:active {
        filter: brightness(0.8);
      }
    }
  }
}
</style>
<style lang="less">
.cdkinput {
  width: 400px;
  margin-right: 20px;
  --el-text-color-regular: #333;
}
</style>
