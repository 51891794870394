<template>
  <el-main style="padding: 0;">
    <transition name="fade-transform" mode="out-in">
      <router-view v-slot="{ Component, route }" class="view">
        <component :is="Component" :key="route.path" />
      </router-view>
    </transition>
  </el-main>
</template>
<script lang="ts" setup>
import { reactive, onMounted } from 'vue'
import router from '../router'

let variateVal = reactive({
  routers: [] as Array<object>,
})
let filterRouter: Array<object>
filterRouter = router.options.routes.filter((item) =>
  item.hidden !== true)
onMounted(() => {
  filterRouter.filter((item: any) => {
    if (item.meta === undefined) {
      variateVal.routers.push(...item.children)
    } else variateVal.routers.push(item)
  })
})
</script>
<style scoped lang="less">
.view {
  min-height: calc(100vh - 354px);
  //  position: absolute;
}

.fade-transform-enter-from,
.fade-transform-leave-to {
  transform: translateX(20px);
  opacity: 0;
}

.fade-transform-enter-to,
.fade-transform-leave-from {
  opacity: 1;
}

.fade-transform-enter-active {
  transition: all 0.7s ease;
}

.fade-transform-leave-active {
  transition: all 0.3s cubic-bezier(1, 0.6, 0.6, 1);
}
</style>
