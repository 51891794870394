<template>
  <div class="pagination-style">
    <el-pagination
      v-model:current-page="currentPage"
      v-model:page-size="pageSize"
      class="pagination"
      layout="total, sizes, prev, pager, next, jumper"
      :page-sizes="props.pageSizes"
      :total="props.total"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    />
  </div>
</template>
<script lang="ts" setup>
import { computed, nextTick } from 'vue'

const props = defineProps({
  total: {
    required: true,
    type: Number,
  },
  page: {
    type: Number,
    default: 1,
  },
  limit: {
    type: Number,
    default: 10,
  },
  pageSizes: {
    type: Array,
    default() {
      return [10, 20, 30, 50]
    },
  },
})
const emit = defineEmits(['pagination', 'update:page', 'update:limit'])
const currentPage = computed({
  get() {
    return props.page
  },
  set(val) {
    emit('update:page', val)
  },
})
const pageSize = computed({
  get() {
    return props.limit
  },
  set(val) {
    emit('update:limit', val)
  },
})
const handleSizeChange = (val: number) => {
  emit('pagination', { page: currentPage, limit: val })
}
const handleCurrentChange = (val: number) => {
  nextTick(() => {
    emit('pagination', { page: val, limit: pageSize })
  })
}
</script>
<style lang="less" scoped>
.pagination-style{
  display: flex;
  justify-content: center;
  margin: 20px 0;
}
</style>
<style lang="less">
.pagination{
  .el-input,.el-select{
    width: 150px !important;
  }
}
</style>
