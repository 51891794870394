<script lang="ts" setup>
import { useRouter } from 'vue-router'
import { ref, reactive } from 'vue'
import { typeList, getPage, getHotPage } from '@/api'

let router = useRouter()
const ci: any = ref(0)
const classList: any = ref([])
const infoList: any = ref([])
const infoHotList: any = ref([])
const total: any = ref(0)
const searchForm = reactive({
  page: 1,
  limit: 10,
  newspaperTypeId: 0,
  source: 10,
})
typeList({ source: 10 }).then((res) => {
  if (res.code === 200) {
    classList.value = res.data
  }
})
const getHotList = () => {
  getHotPage({ source: 10, count: 5 }).then((res) => {
    if (res.code === 200) {
      infoHotList.value = res.data
    }
  })
}
const getList = () => {
  if (classList.value.length === 0) {
    typeList({ source: 10 }).then((res) => {
      if (res.code === 200) {
        classList.value = res.data
        if (classList.value.length !== 0) {
          ci.value = 0
          searchForm.newspaperTypeId = classList.value[ci.value].id
          getPage(searchForm).then((res) => {
            if (res.code === 200) {
              infoList.value = res.data.records
              total.value = parseInt(res.data.total, 10)
            }
          })
        }
      }
    })
  } else {
    searchForm.newspaperTypeId = classList.value[ci.value].id
    getPage(searchForm).then((res) => {
      if (res.code === 200) {
        infoList.value = res.data.records
        total.value = parseInt(res.data.total, 10)
      }
    })
  }
}
getHotList()
getList()
const changeClass = (index: any) => {
  ci.value = index
  getList()
}
const jumpContent = (id: any) => {
  router.push({
    name: 'InfoContent',
    query: { id },
  })
}
</script>
<template>
  <div class="background">
    <div class="text1">加速资讯</div>
    <div class="text2">关注雷电加速最新动态，了解实时加速资讯</div>
    <div class="classlist">
      <div
        v-for="item, index in classList" :key="index" class="item" :class="{ select: index == ci }"
        @click="changeClass(index)">
        {{ item.name }}
      </div>
    </div>
    <el-row style="width: 100%;" :gutter="28">
      <el-col :span="18">
        <div class="box">
          <div v-for="item, index in infoList" :key="index" class="item" @click="jumpContent(item.id)">
            <img class="cover" :src="item.coverImage">
            <div class="main">
              <div class="title">{{ item.title }}</div>
              <div class="info">{{ item.abstractInfo }}</div>
              <div class="down">
                <div class="view">
                  <img class="see" src="@/assets/images/icon_browse.png">
                  {{ item.viewCount }}次
                </div>
                <div class="time">{{ item.createTime.substring(0, 10) }}发布</div>
              </div>
            </div>
          </div>
        </div>
        <Pagination
          v-show="total > 0" v-model:page="searchForm.page" v-model:limit="searchForm.limit" :total="total"
          @pagination="getList" />
      </el-col>
      <el-col :span="6">
        <div class="whitebox">
          <div class="head">热门资讯</div>
          <div v-for="item, index in infoHotList" :key="index" class="item" @click="jumpContent(item.id)">{{ item.title }}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<style lang="less" scoped>
.background {
  width: 100%;
  background-color: #1F2322;
  color: #333333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 72px 180px 154px 180px;

  .text1 {
    font-size: 40px;
    color: #FFFFFF;
    line-height: 56px;
    font-weight: bold;
  }

  .text2 {
    margin: 16px 0 60px 0;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 33px;
  }

  .classlist {
    display: flex;
    overflow-x: scroll;
    width: 100%;

    &::-webkit-scrollbar{
      height: 0;
    }

    .item {
      cursor: pointer;
      width: 180px;
      margin-right: 36px;
      background: rgba(255, 255, 255, 0.1);
      border-radius: 32px;
      font-size: 20px;
      color: #FFFFFF;
      line-height: 60px;
      text-align: center;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      &.select {
        background: #CCFCF8;
        color: #00665D;
      }
    }
  }

  .box {
    margin-top: 32px;
    display: grid;
    grid-template-columns: repeat(auto-fill, 324px);
    grid-template-rows: repeat(1, 380px);
    grid-gap: 28px;

    .item {
      cursor: pointer;
      width: 324px;
      height: 380px;
      background: #FFF;
      border-radius: 10px;

      .cover {
        width: 324px;
        height: 180px;
        border-radius: 10px 10px 0 0;
      }

      .main {
        padding: 24px;

        .title,
        .info {
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          -webkit-box-orient: vertical;
        }

        .title {
          height: 56px;
          font-size: 20px;
          line-height: 28px;
          font-weight: bold;
        }

        .info {
          height: 44px;
          font-size: 16px;
          color: #666;
          line-height: 22px;
          margin: 16px 0 20px 0;
        }

        .down {
          display: flex;
          justify-content: space-between;
          color: #CCC;

          .view {
            display: flex;
            align-items: center;
            font-size: 14px;
            line-height: 20px;

            .see {
              width: 16px;
              height: 16px;
              margin-right: 8px
            }
          }

          .time {
            font-size: 14px;
            line-height: 20px;
          }
        }
      }
    }
  }

  .whitebox {
    background: #fff;
    border-radius: 10px;
    padding: 36px 24px !important;

    .head {
      font-size: 28px;
      line-height: 40px;
      font-weight: bold;
    }

    .item {
      cursor: pointer;
      margin-top: 32px;
      font-size: 20px;
      line-height: 28px;
    }
  }
}
</style>
