<script lang="ts" setup>
import { ref } from 'vue'
import { getQAList } from '@/api'

const si: any = ref(0)
const sj: any = ref(0)
const helpList: any = ref([])
getQAList({ source: 10 }).then((res) => {
  if (res.code === 200) {
    helpList.value = res.data
  }
})
</script>
<template>
  <div class="background">
    <div class="text1">雷电加速器帮助中心</div>
    <div class="text2">您可以通过电子邮件获得专属客服支持</div>
    <div class="text3"><img class="img" src="@/assets/images/icon_mailbox.png">leidiankf@163.com</div>
    <el-row style="width: 100%;" :gutter="40">
      <el-col :span="8">
        <div class="whitebox">
          <div v-for="item, index in helpList" :key="index" class="helplist">
            <div class="head">
              <img v-show="index==0" class="img" src="@/assets/images/icon_acc_20.png">
              <img v-show="index==1" class="img" src="@/assets/images/icon_account_20.png">
              <img v-show="index==2" class="img" src="@/assets/images/icon_vip_20.png">
              <img v-show="index>2" class="img" src="@/assets/images/icon_other_20.png">
              {{ `${index + 1}、${item.name}` }}
            </div>
            <div v-for="i, j in item.questionDetailVoList" :key="j" class="item" :class="{ select: index == si && j == sj }" @click="si=index;sj=j">
              {{ `${j + 1}、${i.question}` }}
            </div>
          </div>
        </div>
      </el-col>
      <el-col :span="16">
        <div class="whitebox">
          <div class="title">{{ helpList[si]?.questionDetailVoList[sj]?.question || '' }}</div>
          <div class="tip">{{ helpList[si]?.questionDetailVoList[sj]?.answer || '' }}</div>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<style lang="less" scoped>
.background {
  width: 100%;
  background-color: #1F2322;
  color: #333333;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 72px 180px 154px 180px;

  .text1 {
    font-size: 40px;
    color: #FFFFFF;
    line-height: 56px;
    font-weight: bold;
  }

  .text2 {
    margin: 16px 0 24px 0;
    font-size: 24px;
    color: #FFFFFF;
    line-height: 33px;
  }

  .text3 {
    margin-bottom: 48px;
    font-size: 24px;
    color: #00DAC6;
    line-height: 33px;
    display: flex;
    align-items: center;

    .img {
      width: 28px;
      height: 28px;
      margin-right: 12px;
    }
  }

  .whitebox {
    background: #fff;
    border-radius: 10px;
    padding: 40px !important;
    min-height: 100%;

    .helplist {
      .head {
        display: flex;
        align-items: center;
        margin: 32px 0 28px 0;
        font-size: 20px;
        line-height: 28px;
        font-weight: bold;

        &:first-child {
          margin-top: 0;
        }

        .img {
          width: 20px;
          height: 20px;
          margin-right: 16px;
        }
      }

      .item {
        cursor: pointer;
        margin-bottom: 20px;
        font-size: 16px;
        line-height: 22px;

        &.select {
          color: #00DAC6;
        }
      }
    }

    .title {
      margin-bottom: 35px;
      font-size: 24px;
      line-height: 33px;
    }

    .tip {
      font-size: 16px;
      line-height: 22px;
    }
  }
}
</style>
