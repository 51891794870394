<template>
  <div class="background">
    <div class="menu">
      <div>
        <img style="width: 173px;height: 36px;" src="@/assets/images/shandian_logo.png">
      </div>
      <el-menu router default-active="/" :ellipsis="false" mode="horizontal">
        <el-menu-item index="/">雷电加速器首页</el-menu-item>
        <el-sub-menu index="/about">
          <template #title>雷电加速器下载</template>
          <el-menu-item class="subdownload">
            <div class="gird22">
              <el-button @click="winDownload">
                <img class="icon" src="@/assets/images/win_sel.png">
                Windows下载
              </el-button>
              <el-button @click="andDownload">
                <img class="icon" src="@/assets/images/android_sel.png">
                Android下载
              </el-button>
              <el-button @click="iosDownload">
                <img class="icon" src="@/assets/images/apple_sel.png">
                iOS下载
              </el-button>
              <el-button @click="macDownload">
                <img class="icon" src="@/assets/images/macos_sel.png">
                MacOS下载
              </el-button>
            </div>
          </el-menu-item>
        </el-sub-menu>
        <el-menu-item index="/vip">会员</el-menu-item>
        <el-menu-item index="/info">加速资讯</el-menu-item>
        <el-menu-item index="/help">问题帮助</el-menu-item>
        <el-menu-item index="/about">关于我们</el-menu-item>
        <el-menu-item index="/cdk">CDK兑换</el-menu-item>
      </el-menu>
      <div v-if="vuex.$state.isLogin" style="color: #fff;font-size: 16px;">
        欢迎您，{{ vuex.$state.name }}&emsp;<span style="font-size: 16px;cursor: pointer;" @click="exit">退出</span>
      </div>
      <el-button v-else class="login" @click="toLogin">登录/注册</el-button>
    </div>
  </div>
  <router-view />
  <div class="downloadnow">
    <div class="nowuse">即刻使用，安全、极速、稳定畅游全球</div>
    <div class="todown" @click="todown">雷电加速器下载</div>
    <div class="tag" />
  </div>
  <div class="footer">
    <el-row>
      <el-col :span="8">软件名称：雷电加速器</el-col>
      <el-col :span="8">版本号：{{ version }}</el-col>
      <el-col :span="8">
        <el-button link @click="jumpUser">《用户协议》</el-button>与<el-button style="margin: 0;" link @click="jumpPirv">
          《隐私协议》
        </el-button>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="8">公司名称：杭州棉致科技有限公司</el-col>
      <el-col :span="8">开发者名称：杭州棉致科技有限公司</el-col>
      <el-col :span="8">联系电话：18239767628</el-col>
    </el-row>
    <el-row>
      <el-col :span="8"><el-button link @click="jumpBeian">浙ICP备2024087172号</el-button></el-col>
      <el-col :span="8">地址：浙江省杭州市余杭区仓前街道鼎创财富中心1幢505-8</el-col>
      <el-col :span="8" />
    </el-row>
  </div>
</template>
<script lang="ts" setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
// import { ElMessage } from 'element-plus'
import { useStore } from '@/store/index'

let router = useRouter()

const vuex = useStore()
vuex.getOptionList()
onMounted(() => {
  if (localStorage.getItem('lsspeedtoken')) {
    vuex.$state.isLogin = true
    vuex.$state.name = localStorage.getItem('lsspeedname') || ''
  }
})
const version = ref('1.0.0')

// vuex.getOptionList()
const toLogin = () => {
  router.push('/login')
}
const exit = () => {
  localStorage.removeItem('lsspeedtoken')
  localStorage.removeItem('lsspeedname')
  vuex.$state.isLogin = false
}
const jumpBeian = () => {
  window.location.href = 'https://beian.miit.gov.cn/#/Integrated/index'
}
const jumpUser = () => {
  window.open('https://leidianshare.mianzhikeji.com/index.html#/thunder/user')
}
const jumpPirv = () => {
  window.open('https://leidianshare.mianzhikeji.com/index.html#/thunder/privacy')
}
const download = (filename: any, url: any) => {
  let a = document.createElement('a')
  a.style.display = 'none' // 创建一个隐藏的a标签
  a.download = filename
  a.href = url
  document.body.appendChild(a)
  a.click() // 触发a标签的click事件
  document.body.removeChild(a)
}
const winDownload = () => {
  let i = vuex.$state.versionList.findIndex((e) =>
    e.plantForm === 3)
  download('雷电加速器', vuex.$state.versionList[i].downloadLink)
  // ElMessage.success('敬请期待')
}
const andDownload = () => {
  let i = vuex.$state.versionList.findIndex((e) =>
    e.plantForm === 1)
  download('雷电加速器', vuex.$state.versionList[i].downloadLink)
  // ElMessage.success('敬请期待')
}
const iosDownload = () => {
  let i = vuex.$state.versionList.findIndex((e) =>
    e.plantForm === 2)
  window.open(vuex.$state.versionList[i].downloadLink)
  // ElMessage.success('敬请期待')
}
const macDownload = () => {
  let i = vuex.$state.versionList.findIndex((e) =>
    e.plantForm === 4)
  download('雷电加速器', vuex.$state.versionList[i].downloadLink)
  // ElMessage.success('敬请期待')
}
const todown = () => {
  const main = document.getElementById('app')
  if (main) { main.scrollTop = 0 }
  // document.getElementById('app').scrollTop = 0
  router.push('/')
}
</script>
<style lang="less" scoped>
.background {
  height: 100%;
  margin-bottom: calc(98px - 100vh);
  background: url('@/assets/images/bg.png') 0 0/cover no-repeat;

  .menu {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px 100px;
    font-size: 16px;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #1F2322;
    z-index: 1;
  }
}

.icon {
  width: 28px;
  height: 28px;
  margin-right: 16px;
}

.login {
  padding: 9px 31px;
}

.downloadnow {
  width: 100%;
  height: 256px;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: url('@/assets/images/downbg.png') 0 0/cover no-repeat;

  >.nowuse {
    font-size: 44px;
    color: #FFFFFF;
    line-height: 62px;
    margin-bottom: 32px;
    z-index: 1;
  }

  >.todown {
    cursor: pointer;
    font-size: 20px;
    color: #FFFFFF;
    padding: 12px 60px;
    line-height: 28px;
    border-radius: 8px;
    border: 1px solid #FFFFFF;
  }

  >.tag {
    width: 452px;
    height: 323px;
    position: absolute;
    left: 79px;
    bottom: 9px;
    background: url('@/assets/images/shandian.png') 0 0/cover no-repeat;
  }
}

.footer {
  width: 100%;
  height: 272px;
  padding: 68px;
  color: #fff;
  font-size: 14px;
  line-height: 38px;
}
</style>
<style lang="less">
.el-menu-item {
  font-size: 16px
}

.el-menu--horizontal,
.el-menu--horizontal>.el-menu-item,
.el-menu--horizontal>.el-menu-item.is-active {
  border: none;
  color: #fff !important;
}

.el-popper.is-pure {
  height: auto;
  background-color: #fff;
  border: none;
}

.subdownload {
  height: 180px !important;
  --el-text-color-primary: #333333;
  --el-text-color-regular: #333333;
}

.gird22 {
  display: grid;
  grid-template-columns: 200px 200px;
  grid-template-rows: 52px 52px;
  gap: 20px;
  margin: 20px;

  >.el-button {
    width: 200px;
    padding: 15px 35px;
    font-size: 16px;
    line-height: 22px;
    height: auto;
    margin: 0;
  }
}

.el-menu {
  background-color: transparent;
}

.el-menu--horizontal>.el-menu-item,
.el-menu--horizontal>.el-sub-menu .el-sub-menu__title {
  font-size: 16px;
  color: #fff;
}

.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover,
.el-menu--horizontal>.el-sub-menu:hover .el-sub-menu__title,
.el-menu--horizontal>.el-menu-item.is-active {
  background-color: transparent;
  color: #fff;

  &::after {
    left: calc(50% - 16px);
    bottom: 4px;
    content: '';
    width: 32px;
    height: 8px;
    background: url('@/assets/images/image／menu_active.png') 0 0/cover no-repeat;
    position: absolute;
  }
}

#chatBtn {
  img {
    width: 25px;
    height: 25px;
    display: inline;
  }
}
</style>
