import request from '../utils/request'

// 套餐列表
export const getProductList = ((data: any) =>
  request({
    url: `sp-product/getList/${data.source}/${data.language}`,
    method: 'post',
  }))
// 订购套餐 获取支付信息
export const orderProduct = ((data: any) =>
  request({
    url: 'sp-product/orderProduct',
    method: 'post',
    data,
  }))
// 支付回调url
export const notifyUrl = ((data: any) =>
  request({
    url: 'sp-product/pay/notifyUrl',
    method: 'post',
    data,
  }))
// 获取对应类型的版本号
export const getVersionList = ((data: any) =>
  request({
    url: `sp-version-manage/getVersionList/${data.source}`,
    method: 'post',
  }))
// 根据ID获取资讯详情
export const getDetailById = ((data: any) =>
  request({
    url: `sp-newspaper/getDetailById/${data.id}`,
    method: 'post',
  }))
// 获取热门资讯
export const getHotPage = ((data: any) =>
  request({
    url: `sp-newspaper/getHotPage/${data.source}/${data.count}`,
    method: 'post',
  }))
// 分页获取资讯
export const getPage = ((data: any) =>
  request({
    url: 'sp-newspaper/getPage',
    method: 'post',
    data,
  }))
// 注销账号
export const cancellation = (() =>
  request({
    url: 'user/manager/cancellation/account',
    method: 'post',
  }))
// 手动操作
export const managerInfo = (() =>
  request({
    url: 'user/manager/info',
    method: 'post',
  }))
// 分类列表
export const typeList = ((data: any) =>
  request({
    url: `/sp-newspaper-type/list/${data.source}`,
    method: 'post',
  }))
// 验证码登录/注册
export const codeLogin = ((data: any) =>
  request({
    url: 'api/pwd/code/login',
    method: 'post',
    data,
  }))
// 手动操作
export const modifyPwd = ((data: any) =>
  request({
    url: 'api/pwd/modifyPwd',
    method: 'post',
    data,
  }))
// 账号密码登录
export const passwordLogin = ((data: any) =>
  request({
    url: 'api/pwd/passwordLogin',
    method: 'post',
    data,
  }))
// 通过账号密码的方式注册账号
export const pwdRegister = ((data: any) =>
  request({
    url: 'api/pwd/register',
    method: 'post',
    data,
  }))
// 忘记密码
export const resetPwd = ((data: any) =>
  request({
    url: 'api/pwd/resetPwd',
    method: 'post',
    data,
  }))
// 获取用户订单数据
export const getOrderList = ((data: any) =>
  request({
    url: 'sp-order-manage/getList',
    method: 'post',
    data,
  }))
// 问题列表
export const getQAList = ((data: any) =>
  request({
    url: `sp-question/getQAList/${data.source}`,
    method: 'post',
    needFormData: true,
  }))
// 获取用户订单数据
export const exchangeCdk = ((data: any) =>
  request({
    url: 'sp-cdk-user/exchangeCdk',
    method: 'post',
    data,
  }))
