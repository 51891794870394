<!-- eslint-disable vue/no-v-html -->
<script lang="ts" setup>
import { useRoute } from 'vue-router'
import { ref, onMounted } from 'vue'
import { getDetailById } from '@/api'

let route = useRoute()
const info: any = ref({})
onMounted(() => {
  getDetailById({ id: route.query.id }).then((res) => {
    if (res.code === 200) {
      info.value = res.data
    }
  })
})
</script>
<template>
  <div class="background">
    <div class="box">
      <h1 class="text1">{{ info.title }}</h1>
      <div class="text2">
        <div class="time">{{ info.createTime?.substring(0, 10) }}发布</div>
        <div class="view">
          <img class="see" src="@/assets/images/icon_browse.png">
          {{ info.viewCount }}次
        </div>
      </div>
      <div class="html" v-html="info.content" />
    </div>
  </div>
</template>
<style lang="less" scoped>
.background {
  width: 100%;
  background-color: #1F2322;
  color: #333333;
  padding: 88px 180px 143px 180px;

  .box {
    width: 100%;
    background: #FFFFFF;
    border-radius: 10px;
    padding: 60px;

    .html {
      :deep(p) {
        font-size: 16px;
        line-height: 32px;
        margin: 0;
      }
    }

    .text1 {
      font-size: 28px;
      line-height: 40px;
      font-weight: bold;
      margin-bottom: 32px;
    }

    .text2 {
      font-size: 14px;
      color: #CCCCCC;
      line-height: 20px;
      display: flex;
      align-items: center;
      padding-bottom: 20px;
      border-bottom: 1px solid #ECEEF0;
      margin-bottom: 20px;

      .time {
        margin-right: 100px;
      }

      .view {
        display: flex;
        align-items: center;

        .see {
          width: 16px;
          height: 16px;
          margin-right: 8px
        }
      }
    }
  }
}
</style>
